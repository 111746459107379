@use '../../assets/shared' as *;

.header {
  padding-block: 1.5rem;
}

.content {
  @include flex-row(null, center, space-between);
  width: min(90%, 1500px);
  margin-inline: auto;

  a {
    text-decoration: none;
    color: $clr-neutral-100;
  }
}

.nav {
  ul {
    @include flex-row(4rem, center);
    font-size: 0.95rem;
  }

  li {
    padding-block: 0.3rem;
    border-bottom: 1px solid $clr-neutral-900;
    transition: border-color 0.3s;

    &:hover, &:focus, &:active {
      border-color: $clr-neutral-100; 
    }
  }
}

.logo {
  font-size: 1.5rem;
  font-weight: 300;

  &:hover {
    animation: hvr-buzz 0.25s linear infinite;
  }
}

.button {
  border: none;
  background: none;
  color: $clr-neutral-100;
  padding-block: 0.1rem;
  display: none;
}

// Logo hover animation
@keyframes hvr-buzz {
  50% {
    transform: translateX(3px) rotate(2deg);
  }

  100% {
    transform: translateX(-3px) rotate(-2deg);
  }
}

@media (max-width: 600px) {
  .content {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    justify-items: right;
    align-items: end;
    width: 94%;

    button:nth-child(3) {
      display: none;
    }
  }

  .nav {
    display: none;
  }

  .logo {
    grid-column: 2/3;
  }

  .button {
    display: block;
  }
}
