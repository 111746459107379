@use '../../assets/shared' as *;

.content {
  width: min(90%, 1500px);
  margin-inline: auto;
}

.anime {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  margin-bottom: 7rem;
}

@media (max-width: 600px) {
  .content {
    width: 94%;
  }

  .anime {
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
    margin-bottom: 0;
  }
}
