@use '../../assets/shared' as *;

.reviews {
  @include flex-col(1rem);
}

.review {
  @include flex-col(1rem);
  font-size: 0.93rem;

  button {
    align-self: center;

    div:nth-child(2) {
      font-size: 0.88rem;
    }
  }
}

.username {
  @include flex-row(null, center, space-between);

  strong {
    font-weight: 400;
  }
}

.rating {
  strong {
    font-weight: 400;
  }
}
