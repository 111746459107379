@use '../../assets/shared' as *;

.relations {
  @include flex-col(1rem);
}

.relation {
  @include flex-row(0.5rem);
  font-size: 0.93rem;

  span {
    white-space: nowrap;
  }

  a {
    color: $clr-neutral-100;
    text-decoration: none;
    border-bottom: 1px solid $clr-neutral-100;
    transition: border-bottom-color 0.3s;
    white-space: nowrap;

    &:hover {
      border-bottom-color: $clr-neutral-900;
    }
  }
}

@media (max-width: 600px) {
  .relations {
    width: 94vw;
    overflow-x: auto;
  }
}
