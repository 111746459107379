@use '../../assets/shared' as *;

.item {
  @include flex-col(1rem);
  border: 1px solid rgba(255, 255, 255, 0);
  padding: 2rem;
  text-align: center;
  text-decoration: none;
  color: $clr-neutral-100;
  height: 100%;
  transition: border-color 0.3s;

  img {
    width: 100%
  }

  h2 {
    font-size: 0.95rem;
    font-weight: 300;
  }

  &:hover {
    border-color: $clr-neutral-100;
  }
}

@media (max-width: 600px) {
  .item {
    padding: 1rem;
  }
}
