@use '../../../assets/shared' as *;

.item {
  display: grid;
  grid-template-columns: 1fr 5fr 1fr 1fr 1fr;
  align-items: center;
  gap: 1rem;
  padding: 0.8rem;
  border: 1px solid $clr-neutral-900;
  transition: border-color 0.3s;

  a:first-child {
    width: 40%;
    margin-inline: auto;
  }

  img {
    width: 100%;
  }

  &:hover {
    border-color: $clr-neutral-100; 
  }
}

.title {
  color: $clr-neutral-100;
  text-decoration: none;
  width:fit-content;
}

.center {
  text-align: center;
  text-transform: capitalize;
}

@media (max-width: 600px) {
  .item {
    grid-template-columns: 1fr 2fr 1fr;

    a:first-child {
      width: 50%;
    }

    div:nth-child(3), div:last-child {
      display: none;
    }
  }
}
