@use '../../assets/shared' as *;

.content {
  width: min(90%, 1500px);
  margin-inline: auto;
}

.info {
  margin-block: 6rem;
  display: grid;
  grid-template-columns: 1fr 5fr;
  gap: 3rem;
}

.attributes {
  @include flex-col(1.2rem);
}

.main {
  @include flex-col(2.5rem);
}

.section {
  h2 {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }

  p {
    white-space: pre-wrap;
    font-size: 0.93rem;
  }
}

@media (max-width: 600px) {
  .content {
    width: 94%;
  }

  .info {
    margin-block: 3rem;
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .attributes {
    flex-direction: row;
    gap: 1.5rem;
    overflow: auto;
  }
}
