@use '../../assets/shared' as *;

.title {
  display: grid;
  grid-template-columns: 25% auto;
  justify-content: center;
  justify-items: center;
  align-items: center;
  column-gap: 5rem;
  row-gap: 1rem;
  margin-block: 6rem;

  img {
    width: min(100%, 730px);
  }

  h1 {
    @include flex-col(0.5rem, center);
    font-size: 2.7rem;
    text-align: center;
  }

  .small {
    font-size: 1.7rem;
  }
}

@media (max-width: 600px) {
  .title {
    grid-template-columns: 40% auto;
    column-gap: 5vw;
    margin-block: 3rem;
  }

  .title_button {
    grid-template-columns: 1fr;

    button {
      grid-row: 2 / 3;
    }

    h1 {
      grid-row: 3 / 4;
      width: auto;
    }
  }

  h1 {
    width: min-content;
  }
}
