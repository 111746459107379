@use '../../assets/shared' as *;

.footer {
  display: flex;
  align-items: flex-end;
  margin-top: 8rem;

  img {
    width: min(40%, 930px);
    position: absolute;
    right: 0;
    user-select: none;
  }
}

.content {
  width: min(90%, 1500px);
  margin-inline: auto;
  padding-block: 1.8rem;
  font-size: 0.95rem;
}

@media (max-width: 600px) {
  .footer {
    margin-top: 4rem;
    
    span {
      display: none;
    }

    img {
      width: 55%
    }
  }

  .content {
    width: 94%;
  }
}
