@use '../../assets/shared' as *;

.hero > div:first-child {
  width: min(90%, 1500px);
  margin-inline: auto;
}

.info {
  display: flex;
  align-items: center;
  margin-block: 8rem;

  img {
    width: min(30%, 770px);
    position: absolute;
    right: 0;
    user-select: none;
  }
}

.content {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  gap: 2.5rem;
  width: min(90%, 1500px);
  margin-inline: auto;
}

.text {
  @include flex-col(3rem);

  p:first-child {
    font-size: 1.3rem;
  }

  p:last-child {
    font-size: 0.9rem;
  }
}

.works {
  width: min(90%, 1500px);
  margin-inline: auto;
}

@media (max-width: 600px) {
  .hero > div:first-child {
    width: 94%;
  }

  .info {
    margin-block: 4rem;
    align-items: flex-end;

    img {
      width: 60%;
    }
  }

  .content {
    grid-template-columns: 1fr;
    width: 94%;
    padding-bottom: 18vw;

    > div:nth-child(2) {
      visibility: hidden;
    }
  }

  .text {
    text-align: center;
    align-items: center;
    gap: 2rem;
  }

  .works {
    width: 94%;
  }
}
