@use '../../assets/shared' as *;

.mobile-nav {
  display: none;
  position: fixed;
  z-index: 5;
}

.nav {
  width: 0;
  height: 100%;
  background: $clr-neutral-900;
  padding-block: 1.5rem;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
  transition: width 0.5s;
  z-index: 5;

  img {
    position: absolute;
    width: 60vw;
    left: 0;
    bottom: 0;
  }
}

.content {
  width: 86%;
  margin-inline: auto;
  @include flex-col(4rem);

  a {
    color: $clr-neutral-100;
    text-decoration: none;
    white-space: nowrap;
  }

  ul {
    @include flex-col(2rem);
    padding-inline: 0.5rem;
  }
}

.logo {
  font-size: 1.5rem;
  font-weight: 300;
  align-self: center;
  white-space: nowrap;
}

@media (max-width: 600px) {
  .mobile-nav {
    display: block;
  }
}
