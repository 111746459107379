@use '../../../assets/shared' as *;

.work {
  margin-block: 6rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-items: center;

  > img {
    width: 60%;
  }
}

.description {
  @include flex-col(3rem);
  max-width: 60%;

  img {
    width: 100%
  }

  h2 {
    text-transform: capitalize;
  }
}

@media (max-width: 600px) {
  .work {
    margin-block: 4rem;
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  .description {
    max-width: 100%;
    align-items: center;
    text-align: center;
    gap: 1.5rem;
  }

  .inv {
    grid-row: 1/2;
  }
}