@use './assets/shared' as *;

// Fonts
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Mono:wght@300;400;500&display=swap');

// General rules
*,
*::after,
*::before {
  box-sizing: border-box;
}

body {
  background: $clr-neutral-900;
  color: $clr-neutral-100;
  font-family: $font-family;
  font-weight: 300;
}

.App {
  display: grid;
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
}

h1, h2, ul, p {
  margin: 0;
}

h1 {
  font-weight: 500;
}

h2 {
  font-weight: 400;
}

ul {
  padding: 0;
  list-style: none;
}

button {
  cursor: pointer;
}

.hidden {
  display: none;
}

@media (min-width: 1536px) {
  :root {
    font-size: 20px;
  }
}
