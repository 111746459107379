@use '../../assets/shared' as *;

.data {
  @include flex-col(0.6rem);
  font-size: 0.85rem;
}

.property {
  font-weight: 400;
}

@media (max-width: 600px) {
  .property {
    white-space: nowrap;
  }

  .value {
    white-space: nowrap;
  }
}
