@use '../../assets/shared' as *;

.overlay {
  background: $clr-neutral-100;
  position: fixed;
  height: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.5s;
  z-index: 5;
}
