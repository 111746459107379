@use '../../../assets/shared' as *;

.section {
  width: 80%;
  margin-block: 3rem;
  margin-inline: auto;

  h2 {
    margin-bottom: 2rem;
    text-transform: capitalize;
  }

  &:last-child {
    margin-bottom: 6rem;
  }
}

.headings {
  display: grid;
  grid-template-columns: 1fr 5fr 1fr 1fr 1fr;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
}

.center {
  text-align: center;
}

@media (max-width: 600px) {
  .section {
    width: 100%;
    margin-block: 2rem;

    &:last-child {
      margin-bottom: 3rem;
    }
  }

  .headings {
    grid-template-columns: 1fr 2fr 1fr;

    div:nth-child(3), div:last-child {
      display: none;
    }
  }
}
